export default [
  {
    component: 'CNavItem',
    name: 'Clientes',
    to: '/clientes',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
    },
  },
]

<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref([])

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        const path = router.resolve({
          name: route.name,
          params: router.currentRoute.value.params,
        }).href

        let name = route.name

        if (route.meta && route.meta.breadcrumb) {
          if (typeof route.meta.breadcrumb === 'function') {
            name = route.meta.breadcrumb(router.currentRoute.value)
          } else {
            name = route.meta.breadcrumb
          }
        }

        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: name,
          path: path,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
</script>

import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Clientes',
    component: DefaultLayout,
    redirect: '/clientes',
    children: [
      {
        path: '',
        redirect: '/clientes',
      },
      {
        path: 'clientes',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: 'declaraciones',
        name: 'DECLARACIONES',
      },
      {
        path: '/declaraciones/fiscal1376/:id/:idDeclaracion/:cedeOrNotCedeDeclaration',
        name: 'Fiscal',
        component: () => import('@/views/declaraciones/Fiscal1376.vue'),
        meta: {
          breadcrumb: (route) => {
            return route.params.cedeOrNotCedeDeclaration === 'cede'
              ? 'Fiscal 1376'
              : 'Fiscal 2178'
          },
        },
      },
      {
        path: '/declaraciones/parametrizacionFiscal/:id',
        name: 'Parametrización Fiscal',
        component: () => import('@/views/declaraciones/Parametrizacion.vue'),
      },
      {
        path: '/declaraciones/fiscal1050/:id/:liquidationId?',
        name: 'Fiscal 1050',
        component: () => import('@/views/declaraciones/Fiscal1050.vue'),
      },
      {
        path: '/datos/usuario',
        name: 'Datos de usuario',
        component: () => import('@/views/login/UserData.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/pages/Page404'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router

<template>
  <router-view />
  <div class="nav" v-if="showNav">
    <router-link to="/url-verifier">URL Verifier</router-link>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<template>
  <CFooter>
    <div>
      <a href="https://coreui.io" target="_blank"></a>
      <span class="ms-1"></span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank"></span>
      <a href="https://coreui.io/vue"></a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
